.header{
    height:90px;
    background-color: transparent !important;
}
/* .colaps{
    background-color: rgb(9, 9, 88);
    margin-left: 333px;
    margin-right: 188px;
    padding-left: 18px;
    color: white;
} */
.basic-nav-dropdown> a{
    color: white;
}
.dropdown> a{
color: white;
}

.dropdown-menu{
    background-color: white;
    
}
.dropdown-item:hover{
    background-color: blue;
}

.images{
    height: 50%;
    width: 100%;
  
    background-color: #FEFFEF;
}

.datas{
    padding-left: 18px;
    padding-top: 12px;
    max-width: 100%;
    overflow-wrap: break-word;
    word-wrap: break-word;
}
.datas>h4{
    /* background-color: blue; */
     color: black;
     font-size: 60px;
     font-weight: 900;
}
.datas>p{
    font-size: 24px;
    font-weight: 500;
    max-width: 100%;
    overflow-wrap: break-word;
    word-wrap: break-word;

}
.explore{
    background: blue;
    position: absolute;
    top: 68px;
    left: 18px;
    color: white;
    padding-left: 10px;
    padding-right: 10px;
}

.colaps{
    background-color: white !important;
    margin-left: 11%;
    margin-right: 3%;
    padding-left: 18px;
    color: black !important;
}

@media (max-width: 991px), (max-width: 768px) {
    .colaps{
        background-color: white !important;
    margin-left: 1%;
    margin-right: 71%;
    padding-left: 0px;
    color: black !important;
    }
  }

  
  #Testimonials {
    text-decoration: underline;
    color: rgb(128, 0, 128);
  }
  
  #background-div {
    background-image: url('background1.jpeg'); 
    background-size:contain; 
    padding: 20px; 
  }

  .custom-modal .modal-dialog {
    display: flex;
    align-items: center;
    min-height: 100vh; /* Ensure full height */
  }

  .customButton {
    background-color: rgb(50, 50, 205) !important;
    border-color: rgb(74, 24, 189) !important;
    color: white !important;
  }
  
  