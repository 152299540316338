.clndr{
    font-family: gc;
    font-weight: 600;
    /* font-family: "Montserrat", sans-serif; */
    margin-top: 64px;
}



p{
    font-size: 14px;
    margin: 0px !important;
}
h5{
    font-size: 18px !important;
    margin: 0px !important;
}
h5:hover{
   color: blue;
   cursor: pointer;
}

.rows{
    display: flex;
    justify-content: space-between;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
}
.capitalize-first-letter::first-letter {
    text-transform: uppercase;
  }



  .flip-card {
    background-color: transparent;
    width: 300px;
    height: 300px;
    perspective: 1000px;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    /* height: 100%; */
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
 
  
  .flip-card-back {
    background-color: #29b9b7;
    color: white;
    transform: rotateY(180deg);
    padding-top: 18px;
  }


  .btnsji{
    width: 200px;
    font-size: 18px;
    margin-top: 100px;
    margin-bottom: 50px;
  }

  .desc{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }


  ul>li{
    font-size: 18px;
  }

  .top-heading{
    font-weight: 800;
    font-size: 41px;
    padding-left: 23px;
    padding-right: 161px;
  }
  .main{
        /* background-image: url((unknown)); */
        position: relative;
        height: 400px;
        width: 888px;
        align-items: center;
        margin-left: 19%;
        margin-top: 6%;
  }

  .head{
    position: absolute;
    top: 28px;
    left: 34%;
    text-align: center;
  }

  hr{
    margin: 0px !important;
    /* color: red; */
    height: 2px;
    background-color: red;
    border: none;
  }
  h6{

  }
  .content>h6{
  color: lightcoral;
  }
   .content{
    background: white;
    padding:8px;
     height: 200px;
   }
   .content>span>h1{
    /* color: lightcoral; */
    padding-top: 26%;
    padding-left: 40%;
  }

  .cell>div{
    height: 40px;
    width: 20px;
    background-color: white;
    /* margin: 22px; */
  }

  .month{
    font-size: 108px;
    text-align: center;
    color: white;
    text-transform: uppercase;
  }