.datatodisplay{
    max-width: 800px !important;
    width: 800px !important;
}
.modal-dialog {
    /* max-width: var(--bs-modal-width); */
    max-width: 1000px !important;
    margin-right: auto;
    margin-left: auto;
}
.modal-content{
    height: 31rem;
}

.modal-header{
    background: linear-gradient(to bottom, #33ccff 0%, #ccffff 100%);
}

.modal-footer{
    background: linear-gradient(to bottom, #99ff99 0%, #99ff66 100%);
}

.modal-body{
    overflow-y: scroll;
    background: linear-gradient(to bottom, #ebedf0 0%, #99ffccc0 100%);;
}

p{
    font-size: 18px;
    word-spacing: 6px;
    font-family: sans-serif;
}