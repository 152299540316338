.row-setting{
    padding: 22px 60px;
}

.left-icons{
    font-size: 18px;
}
.right-icon{
    font-size: 43px;
    cursor: pointer;
}
.right-logo-col{
    display: flex;
    justify-content: space-between;
}

.dark-image{
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
}
.links{
    cursor: pointer;
}