.components{
    margin-top: 65px;
}

.header{
  width: 100%;
}

.navbar-collapse{
    background-color: white !important;
    margin-left: 11%;
    margin-right: 3%;
    padding-left: 143px;
    color: black !important;
}
.navbar-nav{
    margin-right: 0px;
}

.bg-body-tertiary{
    padding: 0px;
}