/* .header{
    background-color: #ec9494 !important;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 30px 20px;
    position: sticky;
    top: 0px;
    width: 100%;
    z-index: 1000;
} */

.header {
    margin-bottom: 20px; /* Adjust the value as per your requirement */
  }
  

.user-profile{
    height: 60px;
    width: 60px;
    border-radius: 100px;
    margin-left: 103px;
}
.navbar-nav>a{
    padding: 7px 30px !important;
}

.navbar-nav{
    margin-right: 129px;
}

.header{
    padding: 29px 70px 70px 10px;
}