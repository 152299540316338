.otp-container{
    
    border: 1px solid;
    border-color: #adb5bd;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    height: 472px;
    margin-top: 5%;
    border-radius: 14px;
}


  

  .otp-box {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    margin: 0 auto;
  }
  
  h1 {
    color: #333;
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  label {
    margin-bottom: 5px;
    color: #333;
  }
  
  input {
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  button {
    padding: 10px;
    background-color: #4caf50;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .error {
    color: red;
  }
  