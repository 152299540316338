


.tag {
	text-align: center;
}

.slick-slide img {
	margin: auto;
	width: 50%;
	height: 50%;
}
